<template>
  <div class="login">
    <!-- <div class="login-header">
      <div class="login-header-l">
        <img :src="exhibition.logo" alt="云展台" @click="$router.push(`/home/index/${exhibitionId}`)" class="f-csp" />
        <p>Sign in</p>
      </div>
    </div> -->
    <div class="login-content" :style="{ backgroundImage: loginBg ? `url(${loginBg})` : `url(${bg})` }">
      <alogin2></alogin2>
    </div>
  </div>
</template>

<script>
const bg = require("@assets/img/navigation/bg.png");
import alogin2 from "./components/alogin2";
import { mapGetters } from "vuex";
export default {
  name: "host-login",
  data () {
    return {
      bodyHeight: "800px",
      bg: bg,
    };
  },
  components: {
    alogin2,
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      loginBg: "getLoginBg",
      exhibition: "getExhibitionInfo"
    }),
  },
  created () {
    this.bodyHeight = window.innerHeight ? window.innerHeight + "px" : "800px";
    document.getElementsByTagName("body")[0].className = "h-100";
    document.getElementsByTagName("body")[0].className = "login_body";
    document.getElementsByTagName("html")[0].className = "h-100";
  },
  methods: {},
  destroyed () {
    document.body.removeAttribute("class", "h-100");
    document.body.removeAttribute("class", "login_body");
    document.getElementsByTagName("html")[0].className = "";
  },
};
</script> 
<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";
.login {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 880px;
  &-header {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-l {
      display: flex;
      align-items: center;
      p {
        margin-left: 10px;

        font-size: 18px;
        color: #333;
        padding-left: 10px;
        border-left: 1px solid #e8e8e8;
      }
    }
    &-r p {
      width: 48px;
      height: 24px;
      font-size: 24px;
      line-height: 18px;
      color: #ededed;
      &:hover {
        cursor: pointer;
      }
    }
  }
  &-content {
    width: 100%;
    flex: 1;
    align-items: center;
    position: relative;
    background: url("./img/bg.png") center center / 1920px 800px no-repeat;
    height: 800px;
    &-footer {
      width: 100%;
      height: 51px;
      position: absolute;
      bottom: 31px;
      color: #fff;
      font-size: 14px;
      .s1 {
        height: 30px;
      }
      .s1 {
        height: 21px;
      }
      .s1,
      .s2 {
        display: block;
        width: 100%;
        text-align: center;
      }
    }
  }
  .f-csp {
    height: 80px;
  }
}
</style>
