<template>
  <div class="login-box" :class="{ center: isCenter, cen: true, is_code: isCode }">
    <div class="login-box-1">
      <div class="login-box-1-top">
        <p class="p2">Sign in to your account</p>
      </div>
      <div class="login-box-1-bottom">
        <!-- <div class="tab_wrap">
          <p :class="{ act: !isCode }" @click="isCode = false">用户名登录</p>
          <p :class="{ act: isCode }" @click="isCode = true">手机号登录</p>
        </div> -->
        <Form v-if="!isCode" class="F" ref="formInline" :model="formInline" :rules="ruleInline" @keyup.enter.native="handleSubmit('formInline')">
          <FormItem class="F-1" prop="email">
            <Input class="I" type="text" v-model="formInline.email" placeholder="E-mail" />
          </FormItem>
          <FormItem class="F-1" prop="password">
            <Input class="I" type="password" v-model="formInline.password" placeholder="Password" />
          </FormItem>
          <!-- <FormItem class="F-1" prop="captcha">
            <Input
              class="I"
              ref="captcha_input"
              maxlength="6"
              v-model="formInline.captcha"
              placeholder="验证码"
              :autocomplete="'new-password'"
            />
            <img
              class="code"
              :src="'data:image/png;base64,' + code.data"
              alt="验证码"
              v-if="code && code.data"
              @click="getCode"
            />
          </FormItem> -->
          <div class="F-2">
            <Checkbox class="save-btn" v-model="remember">&nbsp;&nbsp;&nbsp;Remember me</Checkbox>
            <span class="forget" @click="linkToForget">
              Forget password
            </span>
          </div>

          <Button type="primary" @click="handleSubmit('formInline')" size="large" :loading="loading">Sign in</Button>
        </Form>
        <!-- <Form
          v-else
          class="F"
          ref="formInline1"
          :model="formInline1"
          :rules="ruleInline1"
          @keyup.enter.native="handleSubmit1('formInline1')"
        >
          <FormItem class="F-1" prop="phone">
            <Input
              class="I"
              type="text"
              v-model="formInline1.phone"
              placeholder="手机号"
            />  
          </FormItem>
          <FormItem class="F-1 phone_code" prop="code">
            <Input
              class="I"
              type="text"
              ref="captcha_input2"
              maxlength="6"
              v-model="formInline1.code"
              placeholder="验证码"
              :autocomplete="'off'"
            />
             <Input
              type="password" autocomplete="new-password"
              style="display:none"
            />
            <Button v-if="shoswCode" @click="getPhoneCode">获取验证码</Button>
            <Button v-else disabled style="color: #ccc"
              >{{ count }}s后重新获取</Button
            >
          </FormItem>
          <div class="F-2"></div>
          <Button
            type="primary"
            @click="handleSubmit1('formInline1')"
            size="large"
            :loading="loading"
            >登录</Button
          >
        </Form> -->
        <div class="reg_div">Don't have account <span style="color:#ff5e00;cursor: pointer;" @click="linkToRegSteps">Register</span></div>
        <div class="other">
          <div class="title">Or sign in with</div>
          <div class="other-content">
            <img class="link-icon" @click="signinRedirectLingying" src="@assets/img/linkin.png" />
            <!-- <img class="link-icon" @click="signinRedirectFacebook" src="@assets/img/facebook.png" /> -->
            <!-- <img class="link-icon" @click="signinRedirectTwitter" src="@assets/img/twitter.png" /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CryptoJS from "crypto-js";
import { validator } from "@/other/validator";
import { mapActions, mapMutations, mapGetters } from "vuex";

export default {
  // 自定义登录
  data () {
    const phoneValid = (rule, value, callback) => {
      if (value) {
        if (value) callback();
      } else {
        callback(new Error("请输入手机号"));
      }
    };

    return {
      isCenter: false,
      isCode: false,
      showCode: true,
      timer: null,
      count: "",
      codeKey: "",
      formInline1: {
        phone: "",
        code: "",
      },
      formInline: {
        email: "",
        password: "",
        // captcha: "",
      },
      ruleInline1: {
        phone: validator.phone,
        code: [{ required: true, message: "验证码必填", trigger: "blur" }],
      },
      ruleInline: {
        email: [
          { required: true, message: "Please add an e-mail", trigger: "blur" },
          { type: "email", message: "The mailbox format is incorrect", trigger: "blur" },
        ],
        password: [
          { required: true, message: "Please add password", trigger: "blur" },
          {
            type: "string",
            min: 4,
            message: "The password cannot be less than 4 digits",
            trigger: "blur",
          },
          {
            type: "string",
            max: 50,
            message: "The password cannot be more than 50 digits",
            trigger: "blur",
          },
        ],
        captcha: [{ required: true, message: "验证码必填", trigger: "blur" }],
      },
      code: null, // 二维码信息
      remember: false, //记住我
      loading: false,
    };
  },
  async created () {
    if (this.login) {
      this.signOutOidc();
      this.signOutOidcQQ();
    }
    this.setExhibitionId(this.exhibitionId);
    this.getLoginSet();
    this.init();
    // this.getCode();
  },
  computed: {
    ...mapGetters({
      login: "getLogin",
      token: "getToken",
      company: "loginModule/getCompany",
    }),
    exhibitionId () {
      return this.$route.params.id || "c0226ba0-881c-43ef-b25a-31b7c32004e2";
    },
  },
  methods: {
    ...mapMutations({
      setLoginType: "setLoginType",
      setUser: "setUser",
      setUserId: "setUserId",
      setToken: "setToken",
      setTokenTime: "setTokenTime",
      setLogin: "setLogin",
      setExhibitionId: "setExhibitionId",
      setLoginBg: "setLoginBg",
    }),
    ...mapActions({
      signOutOidc: "signOutOidc",
      signOutOidcQQ: "oidcStoreQQ/signOutOidc",
      graphqlPost: "graphqlPost",
      graphqlPostByZXS: "graphqlPostByZXS",
      authenticateOidc: "authenticateOidc",
      authenticateOidcQQ: "oidcStoreQQ/authenticateOidc",

      oidcSettingsLinkedIn: "oidcStoreLinkedIn/authenticateOidc",
      oidcSettingsFacebook: "oidcStoreFacebook/authenticateOidc",
      oidcSettingsTwitter: "oidcStoreTwitter/authenticateOidc",

      getExToken: "loginModule/getExToken",
      getMsgCode: "getMsgCode",
      getImgCode: "loginModule/getImgCode",
      validateImgCode: "loginModule/validateImgCode",
      getExhibitionInfo: "loginModule/getExhibitionInfo",
      getExListFirst: "loginModule/getExListFirst",
      getExLogin: "hostCommonModule/getExLogin",
      getToken: "loginModule/getToken",
      validateCode: "loginModule/validateCode",
      linkToRegSteps: "linkTo/linkToRegSteps",
      linkToForget: "linkTo/linkToForget",
      enLogin: "en/login",
    }),
    async getLoginSet () {
      let query = `
        query{
          settingsQuery {
            exhibitorLoginUIConfig(
              exhibitionId: "${this.exhibitionId}"
            ) {
              loginProviders {
                enabledUserName
                enabledPhoneNumber
              }
              align
              backgrounds {
                src
              }
            }
          }
        }
      `;
      let opt = {
        query: query,
        variables: {},
      };
      let data = await this.graphqlPostByZXS(opt);
      let selectEx = JSON.parse(JSON.stringify(data.data.settingsQuery.exhibitorLoginUIConfig));
      this.isCenter = selectEx && selectEx.align === 0 ? true : false;
      if (selectEx.backgrounds && selectEx.backgrounds[0] && selectEx.backgrounds[0].src) {
        this.setLoginBg(selectEx.backgrounds[0].src);
      }
    },
    async init () {
      // 获取缓存的账号密码
      let email = this.$cookies.get("ex-email");
      let pwd = this.$cookies.get("ex-pwd");
      if (email && pwd) {
        let password = CryptoJS.AES.decrypt(pwd, "GY-ex-key").toString(CryptoJS.enc.Utf8);
        // console.log("解密结果：" + password);
        this.remember = true;
        this.formInline.email = email;
        this.formInline.password = password;
      }
    },
    // async handleSubmit1(name) {
    //   if (!this.exhibitionId) {
    //     this.$Message.warning("登录地址有误，请联系主办！");
    //     return;
    //   }
    //   let valid = await this.validForm(name);
    //   if (!valid) return;
    //   if (!this.formInline1.code) {
    //     this.$Message.warning("请获取验证码!");
    //     return;
    //   }
    //   let codeValid = await this.validPhoneCode();
    //   if (!codeValid) return;
    //   let opt = {
    //     client_id: "synair-showonline-js",
    //     client_secret: "synair-showonline-js",
    //     grant_type: "phone_number",
    //     scope: "openid profile showonline-api bmm-api phone",
    //     phone_number: this.formInline1.phone,
    //   };
    //   let tokenData = await this.getToken(opt);
    //   if (tokenData) {
    //     this.setToken(tokenData.access_token);
    //     this.setTokenTime(tokenData.expires_in * 1000 + new Date().getTime());
    //     this.setExhibitionId(this.exhibitionId);
    //     await this.getExhibitionInfo(this.exhibitionId);
    //     this.$Message.success("登录成功");
    //     this.setLoginType(1);
    //     this.getUser();
    //   } else {
    //     this.$Message.error("您的手机号或验证码输入有误，请重试！");
    //     this.formInline1.code = "";
    //     this.$refs.captcha_input2.focus();
    //   }
    // },
    async handleSubmit (name) {
      if (!this.exhibitionId) {
        this.$Message.warning("Login address error, please contact the organzier！");
        return;
      }
      let form = this.formInline;
      console.log(form, "ssss");
      let valid = await this.validForm(name);
      if (!valid) return;
      let orgin = location.host.split(".").reverse()[1];
      let clientId = orgin === "zhanshangxiu" ? "synair-showonline-js" : `synair-${orgin}-js`;
      let formData = {
        client_id: clientId,
        client_secret: "synair-showonline-js",
        grant_type: "email",
        scope: "openid profile showonline-api bmm-api phone",
        email: form.email,
        password: form.password,
      };
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 5000);
      let tokenData = await this.enLogin(formData);
      console.log(tokenData, "登录成功---");
      if (tokenData) {
        this.setToken(tokenData.access_token);
        this.setTokenTime(tokenData.expires_in * 1000 + new Date().getTime());
        // 保存密码
        if (this.remember) {
          let pwd = CryptoJS.AES.encrypt(String(form.password), "GY-ex-key").toString();
          this.$cookies.set("ex-email", form.email, 60 * 60 * 24 * 7);
          this.$cookies.set("ex-pwd", pwd, 60 * 60 * 24 * 7);
        } else {
          this.$cookies.remove("ex-email");
          this.$cookies.remove("ex-pwd");
        }
        this.setExhibitionId(this.exhibitionId);
        await this.getExhibitionInfo(this.exhibitionId);
        this.$Message.success("Login successful");
        this.setLoginType(0);
        this.getUser();
      } else {
        this.$Message.error("Your account number or password is incorrect. Please try again！");
        // this.getCode();
      }
      this.loading = false;
    },
    async getCode () {
      // 获取code
      this.code = await this.getImgCode();
    },

    async getPhoneCode () {
      this.$refs.formInline1.validateField("phone", (val) => {
        if (val !== "") return;
        this.formInline1.code = "";
        this.$refs.captcha_input2.focus();
        this.downTime();
      });
    },
    async downTime () {
      const valid = await this.getMsgCode(this.formInline1.phone);
      if (valid && valid.result) {
        this.codeKey = valid.data.key;
      }
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.showCode = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.showCode = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    validForm (name) {
      // 验证表单
      return new Promise((resolve, reject) => {
        this.$refs[name].validate((valid) => {
          resolve(valid);
        });
      });
    },
    async validCode () {
      // 验证code
      if (!this.code || !this.formInline.captcha) return false;
      let valid = await this.validateImgCode({
        Key: this.code.key,
        Code: this.formInline.captcha,
      });
      if (!valid) {
        this.$Message.error("The verification code is wrong，please try again!");
        this.formInline.captcha = "";
        this.$refs.captcha_input.focus();
        this.getCode();
        return false;
      }
      return true;
    },
    async validPhoneCode () {
      // 验证code
      if (!this.codeKey || !this.formInline1.code) return false;
      //原手机认证->下一步 校验验证码，返回之后打开更换手机号弹框
      let valid = await this.validateCode({
        key: this.codeKey,
        code: this.formInline1.code,
      });
      if (!valid) {
        this.$Message.error("The verification code is wrong，please try again!");
        this.formInline1.code = "";
        this.$refs.captcha_input.focus();
        this.getCode();
        return false;
      }
      return true;
    },

    signinRedirect () {
      this.setLoginType(2);
      this.authenticateOidc();
    },
    signinRedirectLingying () {
      this.setLoginType(4);
      this.oidcSettingsLinkedIn();
    },
    signinRedirectQQ () {
      this.setLoginType(3);
      this.authenticateOidcQQ();
    },
    signinRedirectFacebook () {
      this.setLoginType(5);
      this.oidcSettingsFacebook();
    },
    signinRedirectTwitter () {
      this.setLoginType(6);
      this.oidcSettingsTwitter();
    },
    async getUser () {
      let query = `
        query{
          memberQuery{
            current{
              address
              avatarUrl
              balance
              birthday
              businessCard
              city
              company 
              country
              county
              createAt
              deleteAt
              department
              email
              emailConfirmed
              followCount
              id
              inMember(exhibitionId:"${this.exhibitionId}"){ 
                business
                category
                id 
                memberId
                originId
                originLang
                originRemark
                isBlacklist
                originType
                regAt
                sayCount
                sayUserCount
                userId
                userRole
                exhibitorId
                companyUsers{
                  id
                  isAppointment
                  company {
                    nameEn
                  }
                }
              }
              introduction
              isDeleted
              jobTitle
              jobTitleEn
              name
              nameEn
              nameEn
              nickName
              phone
              phoneText
              phoneConfirm
              province
              talkCount
              talkSuccessedCount
              title
              updateAt
              userId
            }
          } 
        }
      `;
      let opt = {
        opt: {
          query: query,
        },
        token: this.token,
      };
      let data = await this.graphqlPost(opt);
      console.log(data, "用户信息");
      if (data.data && data.data.memberQuery && data.data.memberQuery.current) {
        this.setLogin(true);
        this.setUser(data.data.memberQuery.current);
        this.setUserId(data.data.memberQuery.current.userId);
        this.$router.push(`/home/index/${this.exhibitionId}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.login-box {
  width: 384px;
  height: 475px;
  background-color: #fff;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  right: 19.5%;
  top: 204px;
  // transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  &.is_code {
    height: 440px;
    .login-box-1 {
      height: 420px;
    }
    .F {
      .F-2 {
        margin: 12px 0;
      }
    }
  }
  &.center {
    right: calc(50% - 192px);
  }
  &-1 {
    width: 360px;
    height: 455px;
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px rgba(139, 140, 140, 0.3);
    border-radius: 2px;
    position: relative;
    &-top {
      width: 263px;
      height: 40px;
      position: absolute;
      top: 20px;
      left: 43px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .p1 {
        width: 91px;
        height: 17px;
        font-size: 18px;
        line-height: 16px;
        color: #808080;
        cursor: pointer;
        font-weight: bold;
      }
      .p2 {
        width: 100%;
        text-align: center;
        height: 19px;
        font-size: 18px;
        line-height: 16px;
        color: #d13119;
        cursor: pointer;
        font-weight: bold;
      }
      .p3 {
        color: #808080;
        &:hover {
          color: #d13119;
        }
      }
    }
    &-bottom {
      width: 300px;
      position: absolute;
      top: 49px;
      left: 29px;
      .tab_wrap {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        p {
          color: rgba(0, 0, 0, 0.65);
          line-height: 24px;
          font-size: 16px;
          margin-left: 40px;
          cursor: pointer;
          user-select: none;
          transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          &:hover,
          &.act {
            color: #40a9ff;
          }
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
}
.F {
  display: flex;
  flex-direction: column;
  width: 300px;
  position: relative;
  &-1 {
    margin-top: 34px;
    margin-bottom: 0;
    &.phone_code {
      ::v-deep {
        .ivu-form-item-content {
          display: flex;
          .ivu-btn {
            margin-left: 10px;
          }
        }
      }
    } 
  }
  .F-2 {
    width: 100%;
    height: 15px;
    line-height: 15px;
    display: flex;
    justify-content: space-between;
    margin: 24px 0;
    .F-2-text {
      width: 48px;
      height: 13px;
      font-size: 12px;
      color: #333;
      cursor: pointer;
    }
  }
  .wx-btn {
    margin-top: 20px;
  }
  .code {
    width: 70px;
    height: 26px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
    cursor: pointer;
  }
}
.icon-xinxi {
  font-size: 30px;
  cursor: pointer;
  &:hover {
    @include font_color(#1890ff);
  }
}
.c-m {
  margin-top: 30px;
  height: 600px;
  &-one {
    background-color: #fafafa;
    border: 1px solid rgba(221, 221, 221, 0.3);
    border-radius: 5px;
    margin-bottom: 15px;
    height: 100px;
    display: flex;
    cursor: pointer;
    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: 1px solid #e6e6e6;
      margin-left: 20px;
      margin-top: 15px;
    }
    .one-r {
      width: calc(100% - 80px);
      height: 100%;
      padding-left: 20px;
      padding-top: 15px;
      padding-right: 10px;
      h2 {
        font-size: 16px;
        color: #262626;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      p {
        font-size: 14px;
        color: #8c8c8c;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
.c-b {
  text-align: center;
}
.select-input {
  .icon-filter {
    position: absolute;
    right: 2px;
    top: 1px;
    cursor: pointer;
    font-size: 26px;
    color: #ccc;
  }
  .ex-list {
    position: absolute;
    background-color: #fff;
    z-index: 900;
    top: 35px;
    left: 0;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
    padding: 10px 0;
    border-radius: 4px;
    width: 100%;
    p {
      width: 100%;
      padding: 0 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #333;
      &:hover {
        background-color: #f3f3f3;
        cursor: pointer;
      }
    }
  }
}
.other {
  width: 100%;
  background: #fff;
  .title {
    font-size: 14px;
    color: #666;
    text-align: center;
    position: relative;
    margin: 19px 0 15px 0;
    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }
    &::before,
    &::after {
      position: absolute;
      content: "";
      width: 100px;
      height: 1px;
      background: #e6e6e6;
      top: 50%;
      margin-top: -1px;
    }
  }
  &-content {
    display: flex;
    align-items: center;
    justify-content: center;
    .item {
      font-size: 14px;
      color: #666;
      margin-right: 10px;
      cursor: pointer;
      padding-right: 10px;
      display: flex;
      align-items: center;
      position: relative;
      &.s-1 {
        .iconfont {
          color: #49bd38;
        }
      }
      &.s-2 {
        .iconfont {
          color: #17abe3;
        }
      }
      .iconfont {
        font-size: 20px;
        display: inline-block;
        margin-right: 9px;
      }
      &:first-child {
        &::before {
          content: "";
          right: 0;
          top: 50%;
          margin-top: -10px;
          position: absolute;
          height: 20px;
          width: 1px;
          background: #e6e6e6;
        }
      }
    }
    .item-right {
      font-size: 14px;
      color: #ff5e00;
      flex: 1;
      text-align: right;
      span {
        cursor: pointer;
      }
    }
    .link-icon {
      width: 30px;
      cursor: pointer;
      display: inline-block;
      margin: 0 10px;
    }
  }
}
.reg_div {
  text-align: center;
  margin-top: 30px;
}
.forget {
  color: #666;
  cursor: pointer;
}
</style>
